.aside {
  font-weight: 500;
  color: var(--text);
  margin-top: 20px;
  min-width: 255px !important;
}

.aside:global.ant-layout-sider {
  background-color: var(--white);
}

.asideHeader {
  padding: 16px 24px 14px;
  background: var(--white);
  border: 1px solid var(--lines);
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  font-size: 18px;
  line-height: 21px;
}

.asideMenu {
  padding-bottom: 16px;
  border: 1px solid var(--lines);
  border-radius: 0 0 8px 8px;
  font-size: 14px;
  line-height: 16px;
}

.asideMenu:global.ant-menu .ant-menu-item:hover {
  color: var(--accent);
}

.asideMenu:global.ant-menu .ant-menu-item.ant-menu-item-selected {
  background-color: var(--accent);
  color: var(--white);
}

.asideMenu:global.ant-menu .ant-menu-item-selected:after {
  display: none;
}

.asideMenu:global.ant-menu .ant-menu-item-selected .anticon {
  color: var(--white);
}

.asideMenu:global.ant-menu > .ant-menu-item {
  height: 48px;
  margin: 0;
}

.asideMenu:global.ant-menu > .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.asideMenu:global.ant-menu > .ant-menu-item:last-child {
  margin-bottom: 14px;
}

.menuItem:global.ant-menu-item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 475px) {
  .aside {
    width: 100%;
    max-width: none;
  }
}
