.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  background-color: var(--white);
}

.content:global.ant-layout-content {
  position: relative;
  height: 100%;
}

.wrapper {
  position: relative;
}

.mobileSelectWrapper {
  display: none;
  background-color: rgb(242, 242, 242);
  padding: 15px;

  & > div {
    width: 100% !important;
  }
}

@media screen and (max-width: 660px) {
  .mobileSelectWrapper {
    display: block;
  }
}
